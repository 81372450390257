import { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";

export enum FeatureFlagsEnum {
    SUMMARY = "pitch-summary-shipped",
    PREVIEW = "pitch-preview-shipped",
    ONBOARDING_PITCH = "pitch-10-2-onboarding",
    CSV_IMPORT = "pitch-9-2-19-csv-import",
    MATCH_RATES_PROJECTION = "preview-match-rates-projection",
    MATCH_RATES_ACTUAL = "preview-match-rates-actual",
    REPO_RC = "repo-rc",
    PITCH_REPO_V2 = "pitch-repo-v2",
    RUN_DURATION_PREDICTION = "run-duration-prediction",
    NOTIFICATIONS_REVAMP = "pitch-notifications-revamp",
}

export const useFeatureFlag = (flag: string, defaultEnabled: boolean = false) => {
    const posthog = usePostHog();

    const [featureEnabled, setFeatureEnabled] = useState<boolean | undefined>(posthog.isFeatureEnabled(flag));

    useEffect(() => {
        return posthog.onFeatureFlags(() => {
            setFeatureEnabled(posthog.isFeatureEnabled(flag));
        });
    }, [posthog, flag]);

    if (featureEnabled === undefined) return defaultEnabled;
    return featureEnabled;
};
